import { useState, useEffect, useRef } from "react";
import {
  travelExpensesList,
  CardComponent,
  servicesList,
  useStyles,
  GlobalCard,
  PaperComponent,
  useWindowSize,
} from "@mfe/js-common-ave-uiutils";
import {
  es,
  lodash,
  moment,
  parseDateWihtFormat,
  travelSelectionDataLiquidator,
  fetchCitiesData,
  fetchTravelServicesData,
  fetchTravelExpensesData,
  fetchExpensesTypeData,
  fetchTraveLiquidatorData,
  fetchUserSummary,
  fetchTraveLiquidatorByIDData,
  travelSelectionDataLiquidatorEdit,
  fetchTraveLiquidatorPutData,
  travelServicesExpensesData,
  parseDate,
  editFormData,
  fetchUserSummaryData,
  parseDateLocaleFormat,
  formatter,
  currenciesSumTotal,
  formatterUSD,
  fetchTravelByIDData,
} from "@mfe/ts-common-ave-utilitaries";
import TravelComponent from "../components/travel.component";

export default function TravelView({
  filled,
  tokenApi,
  form,
  dataUserSummary,
  setDataUserSummary,
  fareType,
  organization,
  setModalContent,
  open,
  close,
  setHasPolitician,
  savedApprovers,
}) {
  const { classes } = useStyles();
  const isEdit = JSON.parse(localStorage.getItem("isEdit"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [dataUser, setDataUser] = useState(userData);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState("idayvuelta");
  const [servicesSelected, setServicesSelected] = useState([]);
  const [travelCostsSelected, setTravelCostsSelected] = useState([]);
  const [multiTrips, setMultiTrips] = useState([]);
  const [multiTripsSaved, setMultiTripsSaved] = useState([]);
  const [changeUserEdit, setChangeUserEdit] = useState(true);
  const [lastDate, setLastDate] = useState(null);
  const inputRef = useRef(null);

  const [data, setData] = useState({
    travelServices: [],
    travelExpenses: [],
    travelExpensesType: [],
    travelExpensesTypeSelected: [],
    travelDestinations: [],
    travelLiquidatorData: {
      TipoViajeId: "",
      ComisionId: "",
      codigoElementoPresupuestal: "",
      NombreEmpleadoAprobador: "",
      descripcion: "",
      ViajeGasto: [],
      ViajeTrayecto: [],
      tipoEmergenciaId: "",
      descricionEmergencia: "",
      tipoArticuloSindicalId: "",
      termsOfService: false,
      Multiviaje: false,
      CodigoEmpleadoViajero: "",
    },
    travelStatus: {
      listaDestinos: [],
    },
  });

  const [originValue, originGroup, originCity] = form.values.origin.split("/");

  const [destinationValue, destinationGroup, destinationCity] =
    form.values.destination.split("/");

  const [travelExpensesID, travelExpensesName, travelExpensesAlias] =
    form.values.travelExpenses.name.split("/");

  const servicesSelectedSome = (aliasValue) =>
    servicesSelected.some(({ alias }) => alias === aliasValue);

  const modalTexts = {
    woServices:
      form.values.travelType === 7
        ? "Se creará la comisión sin servicio de Vuelos."
        : "Se creará la comisión sin servicios de viajes como: Hotel, Vuelos, etc.",
    woTravelExpenses:
      "Se creará la comisión sin viáticos como: Alimentación, Alojamiento etc.",
    woBoth: "Se creará la comisión sin servicios de viajes y viáticos",
    sameDateAndHotel:
      "No se puede tener servicios de Hotel para una comisión de un solo dia",
  };

  const getApproverByID = async () => {
    if (form.values.thirdParties || form.values.travelType === 2) {
      setHasPolitician(false);
      return;
    }
    const { data } = await fetchUserSummary(
      tokenApi,
      dataUserSummary?.travelUserSummary?.jefeJefe?.length > 0
        ? dataUserSummary?.travelUserSummary?.jefeJefe
        : dataUser.datosUsuario.jefeJefe
    );

    form.setFieldValue("travelApprover", {
      nombre: data.nombre,
      codigoEmpleado: data.codigoEmpleado,
    });
    form.setFieldValue("travelPreviousApprover", {
      nombre: savedApprovers?.preApprover?.nombre || "",
      codigoEmpleado: savedApprovers?.preApprover?.codigoEmpleado || "",
    });
    form.setFieldValue("travelAproverBossBoss", data.codigoEmpleado);
  };

  useEffect(() => {
    if (tokenApi) {
      if (form.isDirty("destination")) {
        inputRef?.current?.blur();
        fetchTravelServicesData(
          setData,
          tokenApi,
          fareType?.tipoUsuario,
          destinationValue,
          form.values
        );
        if (form.values.travelType !== 6 && !form.values.thirdParties) {
          if (changeUserEdit)
            fetchTravelExpensesData(
              setData,
              tokenApi,
              {
                ...form.values,
                originValue: originValue,
                destinationValue: destinationValue,
                tipoUsuario: fareType?.tipoUsuario,
                idCategoria: fareType?.idCategoria,
              },
              setModalContent,
              open,
              form
            );
          fetchExpensesTypeData(setData, tokenApi);
        }
      }
    }
  }, [
    tokenApi,
    form.values.destination,
    form.values.travelType,
    changeUserEdit,
    fareType,
  ]);

  useEffect(() => {
    localStorage.removeItem("aidBeneficiaryData");
  }, []);

  useEffect(() => {
    const hasMultiTrips = multiTrips?.length > 0;
    if (!form.values.dates.includes(null)) {
      if (!hasMultiTrips) {
        setHasPolitician(false);
      }
      fetchCitiesData(
        setData,
        tokenApi,
        form.values.travelType,
        parseDateWihtFormat(form.values.dates[0], "YYYY-MM-DD"),
        setModalContent,
        open,
        getApproverByID,
        setHasPolitician
      );
    }
    if (form.values.dates.every((date) => date === null) && !hasMultiTrips) {
      setHasPolitician(false);
    }
  }, [JSON.stringify(form.values.dates)]);

  useEffect(() => {
    if (
      data?.travelExpenses.length > 0 &&
      !form.values.thirdParties &&
      form.values.travelType !== 6 &&
      !isEdit &&
      !(form.values.travelType === 7 && form.values.travelCommission === 5)
    ) {
      setTravelCostsSelected(
        travelServicesExpensesData(
          data?.travelExpenses,
          travelExpensesList,
          true
        )
      );
    }
  }, [data?.travelExpenses, isEdit]);

  useEffect(() => {
    if (
      data?.travelServices?.length > 0 &&
      form.values.travelType === 6 &&
      !isEdit
    ) {
      setServicesSelected(
        travelServicesExpensesData(data?.travelServices, servicesList, true)
      );
    }
  }, [data?.travelServices, isEdit]);

  useEffect(() => {
    if (tokenApi && isEdit) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );

      fetchTravelByIDData(
        tokenApi,
        localStorage.getItem("liquidationID"),
        setData,
        setLoading
      );

      if (
        data?.travelLiquidatorData.ViajeTrayecto.length &&
        data?.travelStatus?.listaDestinos?.length
      ) {
        editFormData(
          form,
          data,
          setData,
          setValue,
          setTravelCostsSelected,
          setServicesSelected,
          setMultiTrips,
          setMultiTripsSaved
        );
        form.setFieldValue(
          "changeUser",
          data?.travelLiquidatorData?.CodigoEmpleadoViajero
        );
        fetchUserSummaryData(
          setDataUserSummary,
          () => {},
          tokenApi,
          data?.travelLiquidatorData?.CodigoEmpleadoViajero,
          setChangeUserEdit
        );
      }
    }
  }, [
    isEdit,
    tokenApi,
    data?.travelLiquidatorData?.ViajeTrayecto?.length,
    data?.travelStatus?.listaDestinos?.length,
  ]);

  useEffect(() => {
    if (isEdit) {
      if (data?.travelLiquidatorData?.ViajeTrayecto?.length) {
        if (
          !data?.travelLiquidatorData?.CodigoEmpleadoViajero?.toLocaleLowerCase().startsWith(
            "e"
          ) &&
          changeUserEdit &&
          organization === "ecopetrol"
        ) {
          form.setFieldValue("thirdParties", true);
        }
      }
    }
  }, [
    isEdit,
    data?.travelLiquidatorData?.ViajeTrayecto?.length,
    changeUserEdit,
  ]);

  useEffect(() => {
    if (form.values.dates[0] && form.values.dates[1]) {
      setLastDate(form.values.dates[0]);
    } else if (value === "idayvuelta") {
      setLastDate(null);
    }
  }, [form.values.dates[1]]);

  useEffect(() => {
    if (value === "multidestino") {
      if (multiTrips.length > 0) {
        const lastDate = multiTrips[multiTrips.length - 1].dates[1];
        setLastDate(lastDate);
      } else {
        setLastDate(null);
      }
    }
  }, [multiTrips.length, value]);

  useEffect(() => {
    if (
      moment(form.values.dates[0], "YYYY-MM-DD").isSame(
        moment(form.values.dates[1], "YYYY-MM-DD")
      ) &&
      servicesSelectedSome("HOTEL")
    ) {
      setModalContent({
        content: modalTexts["sameDateAndHotel"],
        closeOnClickOutside: true,
        onClick: () => {},
        showButtons: false,
      });
      open();
      setServicesSelected(
        servicesSelected.filter(({ alias }) => alias !== "HOTEL")
      );
    }
  }, [form.values.dates, servicesSelected]);

  const SERVICES_ALIAS_CNT = {
    ESPTERREST: ["TRANSPRLOC", "VLRTAXI"],
    HOTEL: ["VLRALOJAM"],
  };

  useEffect(() => {
    if (
      userData?.datosUsuario?.permisosUnicos?.includes("viajes Cenit") &&
      fareType?.tipoUsuario === "100"
    ) {
      const filteredTravelCosts = travelCostsSelected.filter(({ alias }) => {
        return !Object.keys(SERVICES_ALIAS_CNT).some((serviceAlias) => {
          if (servicesSelectedSome(serviceAlias)) {
            return SERVICES_ALIAS_CNT[serviceAlias].includes(alias);
          }
          return false;
        });
      });

      setTravelCostsSelected(filteredTravelCosts);
    }
  }, [
    servicesSelected.length,
    travelCostsSelected.length,
    fareType?.tipoUsuario,
  ]);

  const showModalValidation = () => {
    if (!!multiTrips.length) {
      fetchTraveLiquidator();
    } else {
      if (!!servicesSelected.length && !!travelCostsSelected.length) {
        fetchTraveLiquidator();
      } else {
        if (!servicesSelected.length) {
          setModalContent({
            content: modalTexts["woServices"],
            closeOnClickOutside: true,
            onClick: () => fetchTraveLiquidator(),
            showButtons: true,
          });
          open();
        }
        if (!travelCostsSelected.length) {
          if (
            form.values.travelType === 6 ||
            form.values.thirdParties ||
            (form.values.travelType === 7 && form.values.travelCommission === 5)
          ) {
            fetchTraveLiquidator();
          } else {
            setModalContent({
              content: modalTexts["woTravelExpenses"],
              closeOnClickOutside: true,
              onClick: () => fetchTraveLiquidator(),
              showButtons: true,
            });
            open();
          }
        }
        if (!servicesSelected.length && !travelCostsSelected.length) {
          setModalContent({
            content: modalTexts["woBoth"],
            closeOnClickOutside: true,
            onClick: () => fetchTraveLiquidator(),
            showButtons: true,
          });
          open();
        }
      }
    }
  };

  const excludeDate = (date) => {
    const dateToCheck = moment(date);

    return multiTrips.some((trip) => {
      const [start, end] = trip.dates.map((date) => moment(date));
      return dateToCheck.isBefore(end) || dateToCheck.isSame(end);
    });
  };

  const handleAddTravelExpensesType = () => {
    setData((prev) => ({
      ...prev,
      travelExpensesTypeSelected: [
        ...prev.travelExpensesTypeSelected,
        {
          id: travelExpensesID,
          name: travelExpensesName,
          alias: travelExpensesAlias,
          value: form.values.travelExpenses.value,
          currency: form.values.travelExpensesDollar ? "USD" : "COP",
        },
      ],
    }));
    form.setFieldValue("travelExpenses.value", "");
    form.setFieldValue("travelExpenses.name", "");
  };
  const handleMultiTrip = () => {
    setMultiTrips((prev) => [
      ...prev,
      {
        dates: form.values.dates,
        servicesExpensesSelected: [...servicesSelected, ...travelCostsSelected],
        originValue: originValue,
        originGroup: originGroup,
        originCity: originCity,
        destinationValue: destinationValue,
        destinationGroup: destinationGroup,
        destinationCity: destinationCity,
        travelCostsSelected: travelCostsSelected,
        servicesSelected: servicesSelected,
        isMultiTrips: true,
      },
    ]);

    form.setFieldValue("dates", [null, null]);
    form.setFieldValue("origin", "");
    form.setFieldValue("destination", "");
  };

  const handleAddMultiTrip = () => {
    handleMultiTrip();
    setServicesSelected([]);
    setTravelCostsSelected([]);
  };

  const handleChangeInput = (e) => {
    const inputValue = e.target.value;
    if (
      /^\d*$/.test(inputValue) &&
      !/[^\d]/.test(inputValue) &&
      inputValue.length <= 7
    ) {
      form.setFieldValue("travelExpenses.value", inputValue);
    }
  };

  const spliceDataToMap = {
    form: form,
    travelLiquidatorData: data?.travelLiquidatorData,
    process:
      (value === "multidestino" && (form.values.travelType === 7 ? 2 : 1)) ||
      (value === "idayvuelta" && 3),
    dataUser: dataUser,
    originValue: originValue,
    originGroup: originGroup,
    originCity: originCity,
    destinationValue: destinationValue,
    destinationGroup: destinationGroup,
    destinationCity: destinationCity,
    travelCostsSelected: travelCostsSelected,
    servicesSelected: servicesSelected,
    travelExpensesTypeSelected: data?.travelExpensesTypeSelected,
    isMultiTrips: value === "multidestino",
    isOutpatientcare: false,
    multiTrips: multiTrips,
    multiTripsSaved: multiTripsSaved,
    dataUserSummary,
    fareType,
  };

  const fetchTraveLiquidator = () => {
    isEdit
      ? fetchTraveLiquidatorPutData(
          tokenApi,
          setLoading,
          travelSelectionDataLiquidatorEdit(spliceDataToMap)
        )
      : fetchTraveLiquidatorData(
          tokenApi,
          setLoading,
          setModalContent,
          travelSelectionDataLiquidator(spliceDataToMap),
          open,
          spliceDataToMap
        );
    close();
  };

  const handleClickSubmit = () => {
    showModalValidation();
  };

  const utils = {
    lodash,
    es,
    moment,
    parseDate,
    formatter,
    currenciesSumTotal,
    formatterUSD,
    parseLocale: parseDateLocaleFormat,
  };
  const props = {
    loading,
    data,
    setData,
    multiTrips,
    setMultiTrips,
    form,
    value,
    setValue,
    filled,
    setServicesSelected,
    setTravelCostsSelected,
    travelCostsSelected,
    travelServicesExpensesData,
    servicesSelected,
    utils,
    handleClickSubmit,
    lastDate,
    setLastDate,
    CardComponent,
    classes,
    travelExpensesList,
    servicesList,
    GlobalCard,
    PaperComponent,
    useWindowSize,
    excludeDate,
    handleAddTravelExpensesType,
    handleAddMultiTrip,
    handleChangeInput,
    inputRef,
    fareType,
  };

  return <TravelComponent {...props} />;
}
