import { Flex, Text, Grid, Button, Tooltip } from "@mantine/core";
import { FaPlus } from "react-icons/fa6";
import moment from "moment";

const MultiDestinationCard = ({
  multiTrips,
  setMultiTrips,
  utils,
  GlobalCard,
  classes,
}) => {
  return (
    <>
      {!!multiTrips?.length && <Text c="white">Multidestinos</Text>}
      {multiTrips?.map((item, index) => (
        <GlobalCard
          key={index}
          styles={{
            padding: "10px",
            position: "relative",
            overflow: "unset",
          }}
        >
          <Grid columns={4} align="center" justify="center">
            <Grid.Col xs={2} md={1}>
              <Flex direction="column" align="center">
                <Text span fw={700}>
                  {item.originCity}
                </Text>
                <Text span size="xs" fw={700}>
                  {moment(item.dates[0]).format("DD-MMM-YY")}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col xs={2} md={1}>
              <Flex direction="column" align="center">
                <Text span fw={700}>
                  {item.destinationCity}
                </Text>
                <Text span size="xs" fw={700}>
                  {moment(item.dates[1]).format("DD-MMM-YY")}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={"content"}>
              <Flex gap="8px" align="center" justify="center">
                {item.servicesExpensesSelected?.map((service, index) => (
                  <Tooltip
                    key={index}
                    label={service.title}
                    color="#fff"
                    sx={{
                      color: "#000",
                      textTransform: "capitalize",
                    }}
                    events={{ hover: true, focus: true, touch: true }}
                  >
                    <Button
                      className={classes.buttonOutline}
                      sx={{
                        border: "1px solid #cbd300",
                        paddingInline: "0.5rem",
                        "& svg": {
                          fill: "#cbd300",
                        },
                      }}
                    >
                      {service.icon}
                    </Button>
                  </Tooltip>
                ))}
              </Flex>
            </Grid.Col>
            <Grid.Col
              span={"content"}
              sx={(theme) => ({
                [theme.fn.smallerThan("md")]: {
                  position: "absolute",
                  top: "0",
                  right: "0",
                },
                marginLeft: "auto",
              })}
            >
              <FaPlus
                size={24}
                style={{
                  transform: "rotate(45deg)",
                  cursor: "pointer",
                }}
                data-testid="plus-icon"
                onClick={() =>
                  setMultiTrips((prev) => prev.filter((_, i) => i !== index))
                }
              />
            </Grid.Col>
          </Grid>
        </GlobalCard>
      ))}
    </>
  );
};

export default MultiDestinationCard;
