import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Group,
  Image,
  Loader,
  Popover,
  Radio,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Textarea,
  UnstyledButton,
  Skeleton,
} from "@mantine/core";
import { FaUserDoctor, FaPlus } from "react-icons/fa6";
import { BiSearchAlt2, BiSolidPlaneAlt } from "react-icons/bi";
import { LuChevronDown } from "react-icons/lu";
import { FaEdit, FaComment, FaSuitcase, FaUserAlt } from "react-icons/fa";
import { MdArticle } from "react-icons/md";
import Label from "./label.component";

const TravelerComponent = ({
  loading,
  form,
  data,
  utils,
  handleChangeTravelUser,
  handleSearchUser,
  opened,
  setOpened,
  dataUser,
  dataUserSummary,
  photo,
  handleSearchBudgets,
  setData,
  elementSearchEvent,
  loadingsChangeUser,
  fareType,
  classes,
  CardComponent,
  SelectItem,
  handleChangeApprover,
  handleChangeBugetElement,
  openedApprover,
  setOpenedApprover,
  openedBudget,
  setOpenedBudget,
  organization,
  hasPolitician,
  loadingBudget,
  loadingListApprovers,
  handleChangeManagerProfile,
  managerNumber,
  loadingChangeManager,
}) => {
  const searchData = (data, fields, searchValue) => {
    const filterFunction = (item) => {
      const lowerValue = searchValue.toLowerCase();
      return fields.some((field) => {
        const value = item?.[field]?.toLowerCase();
        return value?.includes(lowerValue);
      });
    };
    return data?.filter(filterFunction) || [];
  };

  const searchThirdPerson = searchData(
    data?.travelThirdChangeUser,
    ["codigoEmpleado"],
    form.values.travelChangeUser
  );

  const searchPreviousApprovers = searchData(
    data?.travelPreApprovers,
    ["nombre", "codigoEmpleado"],
    form.values.travelApprovers
  );

  const searchApprovers = searchData(
    data?.travelApprovers,
    ["nombre", "codigoEmpleado"],
    form.values.travelApprovers
  );

  const searchBudgetElements = searchData(
    data?.travelBudget?.elements,
    ["value"],
    form.values.travelBudgetSearch
  );

  const validate = form.values.travelType;

  const handlePreviousApproverClick = () => {
    form.setFieldValue("travelPreviousApprover", {
      nombre: "",
      codigoEmpleado: "",
    });
    handleChangeApprover(form.values.travelApprover, false);
  };

  const dataList = (data, onClick, isPrevious = false, isBudget = false) => {
    return (
      <ScrollArea.Autosize mt={10} mah={150} maw={400} mx="auto">
        <Table>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <Flex justify="center" align="center" p="1rem">
                    <Loader variant="dots" color="#cbd300" />
                  </Flex>
                </td>
              </tr>
            ) : (
              data?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Box
                      sx={{
                        padding: ".5rem",
                        "&:hover": {
                          cursor: "pointer",
                          backgroundColor: "#e3eb99",
                        },
                      }}
                      data-testid="list-button"
                      onClick={() =>
                        isPrevious ? onClick(item, isPrevious) : onClick(item)
                      }
                    >
                      {isBudget ? (
                        <>
                          <Text>{item.value}</Text>
                          <Text>{item.description}</Text>
                        </>
                      ) : (
                        <>
                          <Text>
                            {utils.lodash.startCase(
                              utils.lodash.camelCase(item.nombre)
                            )}
                          </Text>
                          <Text>{item.codigoEmpleado}</Text>
                        </>
                      )}
                    </Box>
                  </td>
                </tr>
              )) || []
            )}
          </tbody>
        </Table>
      </ScrollArea.Autosize>
    );
  };

  return (
    <CardComponent
      title="Información del "
      subtitle="viajero"
      visible={false}
      styles={{ overflow: "unset" }}
      button={
        <Button onClick={() => location.reload()} className={classes.button}>
          Limpiar
        </Button>
      }
    >
      <Grid align="center" mt={12}>
        <Grid.Col span="content" sx={{ padding: "1.5rem 0" }}>
          {loadingsChangeUser?.photo ? (
            <Skeleton height={100} circle />
          ) : (
            <Image
              maw={100}
              mx="auto"
              radius="50%"
              style={{
                border: "3px solid #cbd300",
                borderRadius: "50%",
              }}
              sx={{
                ".mantine-Image-image": {
                  aspectRatio: "1/1",
                },
              }}
              src={photo || ""}
              alt="User Image"
              withPlaceholder
              placeholder={
                <Image
                  maw={100}
                  mx="auto"
                  radius="50%"
                  src={dataUser?.responseGraph?.photo}
                  style={{
                    borderRadius: "50%",
                  }}
                />
              }
            />
          )}
        </Grid.Col>

        <Grid.Col span="auto">
          {loadingsChangeUser?.data ? (
            <Flex direction="column" gap={8}>
              <Skeleton height={15} radius="sm" />
              <Skeleton height={15} radius="sm" />
              <Skeleton height={15} radius="sm" />
            </Flex>
          ) : (
            <>
              <Text size="sm" color="white" fw="700">
                {utils.lodash.startCase(
                  utils.lodash.camelCase(
                    form.values.changeUser
                      ? dataUserSummary?.travelUserSummary?.nombre
                      : dataUser?.datosUsuario?.nombre
                  )
                )}
              </Text>
              <Text size="sm" color="#cbd300">
                {organization === "ecopetrol"
                  ? `${utils.lodash.startCase(
                      utils.lodash.camelCase(
                        form.values.changeUser
                          ? dataUserSummary?.travelUserSummary?.nombreCargo
                          : dataUser?.datosUsuario?.nombreCargo
                      )
                    )} / ${utils.lodash.startCase(
                      utils.lodash.camelCase(
                        form.values.changeUser
                          ? dataUserSummary?.travelUserSummary?.tipoNomina
                          : dataUser?.datosUsuario?.tipoNomina
                      )
                    )}`
                  : utils.lodash.startCase(
                      utils.lodash.camelCase(
                        form.values.changeUser
                          ? dataUserSummary?.travelUserSummary?.nombreCargo
                          : dataUser?.datosUsuario?.nombreCargo
                      )
                    )}
              </Text>
              <Text size="sm" color="#cbd300">
                {`Su viaje será gestionado como `}
                <span style={{ fontWeight: "bold" }}>
                  {fareType?.desPerfilViajero}
                </span>
              </Text>
            </>
          )}
          {dataUser?.rules?.configuracionInicio?.habilitaCambioComisionado ? (
            <Popover
              position="bottom-start"
              opened={opened}
              onChange={setOpened}
              closeOnClickOutside
            >
              <Popover.Target>
                <UnstyledButton
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <FaEdit size="1.1rem" color="#cbd300" />
                  <Text
                    size="sm"
                    color="white"
                    data-testid="changetraveler-button"
                    onClick={() => setOpened(!opened)}
                  >
                    Cambiar Viajero
                  </Text>
                </UnstyledButton>
              </Popover.Target>
              <Popover.Dropdown style={{ marginTop: ".5rem" }}>
                {managerNumber !== 0 && (
                  <Button
                    className={classes.button}
                    onClick={handleChangeManagerProfile}
                    my={8}
                    loading={loadingChangeManager}
                  >
                    {`Cambiar a gestor ${
                      managerNumber === 1 ? "Sindical" : "de Viajes"
                    }`}
                  </Button>
                )}

                <Text size="sm">
                  Buscar {form.values.thirdParties ? "tercero" : "usuario"}
                  {organization === "ecopetrol" &&
                    !form.values.thirdParties && (
                      <Text
                        span
                        inherit
                        color="#004236"
                        size="sm"
                        fw={700}
                      >{` (E00xxxxxxx)`}</Text>
                    )}
                </Text>

                {dataUser?.datosUsuario?.permisosUnicos?.includes(
                  "habilitar Check Terceros"
                ) && (
                  <Checkbox
                    checked={form.values.thirdParties}
                    onChange={(event) =>
                      form.setFieldValue(
                        "thirdParties",
                        event.currentTarget.checked
                      )
                    }
                    className={classes.checkBox}
                    label="Viajes a terceros"
                  />
                )}
                <Grid justify="center" align="flex-start">
                  <Grid.Col span="auto">
                    <TextInput
                      {...form.getInputProps("travelChangeUser")}
                      icon={<BiSearchAlt2 size="1rem" color="#004236" />}
                      placeholder={
                        form.values.thirdParties
                          ? "Ingresar la cédula del tercero"
                          : "Ingresar código de empleado"
                      }
                      classNames={{
                        label: classes.label,
                        input: classes.input,
                      }}
                      rightSection={
                        form.values.travelChangeUser.length > 0 && (
                          <FaPlus
                            size="1rem"
                            color="#004236"
                            style={{
                              transform: "rotate(45deg)",
                              cursor: "pointer",
                            }}
                            data-testid="changeUser-plus"
                            onClick={() =>
                              form.setFieldValue("travelChangeUser", "")
                            }
                          />
                        )
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button
                      disabled={!form.isValid("travelChangeUser")}
                      className={classes.button}
                      onClick={handleSearchUser}
                    >
                      Buscar
                    </Button>
                  </Grid.Col>
                </Grid>
                {form.values.thirdParties
                  ? dataList(searchThirdPerson, handleChangeTravelUser)
                  : dataList(data?.travelChangeUser, handleChangeTravelUser)}
              </Popover.Dropdown>
            </Popover>
          ) : null}
        </Grid.Col>
      </Grid>
      <Grid columns={2}>
        <Grid.Col xs={2} md={1}>
          <Select
            {...form.getInputProps("travelType")}
            transitionProps={{ transition: "pop" }}
            dropdownPosition="bottom"
            label="Tipo de viaje"
            placeholder="Tipo de viaje"
            icon={
              <BiSolidPlaneAlt
                size="1.2rem"
                color={form.isDirty("travelType") && "#004236"}
              />
            }
            rightSection={<LuChevronDown size="1rem" color="#004236" />}
            rightSectionWidth={30}
            itemComponent={(props) => (
              <SelectItem
                {...props}
                formValue={form.values.travelType}
                selectType="radio"
              />
            )}
            data={data.travelType}
            classNames={{
              dropdown: classes.dropdown,
              rightSection: classes.rightSection,
              label: classes.label,
              input: classes.input,
              item: classes.item,
            }}
            disabled={!data?.travelType?.length}
          />
        </Grid.Col>
        <Grid.Col xs={2} md={1}>
          <Select
            {...form.getInputProps("travelCommission")}
            transitionProps={{ transition: "pop" }}
            dropdownPosition="bottom"
            label="Tipo de comisión"
            placeholder="Tipo de comisión"
            icon={
              <FaSuitcase
                size="1rem"
                color={form.isDirty("travelCommission") && "#004236"}
              />
            }
            rightSection={<LuChevronDown size="1rem" color="#004236" />}
            rightSectionWidth={30}
            itemComponent={(props) => (
              <SelectItem {...props} formValue={form.values.travelCommission} />
            )}
            data={data.travelCommission}
            classNames={{
              dropdown: classes.dropdown,
              rightSection: classes.rightSection,
              label: classes.label,
              input: classes.input,
              item: classes.item,
            }}
            disabled={
              !data?.travelCommission?.length || form.values.travelType === 6
            }
          />
        </Grid.Col>

        <Grid.Col span={2} md={1}>
          <Popover
            position="bottom-start"
            opened={openedBudget}
            onChange={setOpenedBudget}
            closeOnClickOutside
          >
            <Popover.Target>
              <TextInput
                {...form.getInputProps("travelBudget")}
                placeholder="Centro de costo"
                readOnly
                label={
                  <Label
                    title="Información presupuestal"
                    description="Centro de costo: Seleccione el elemento presupuestal que puede ser un centro de costo, un proyecto, un grafo o una orden interna."
                  />
                }
                data-testid="budgetElement"
                onClick={() => setOpenedBudget(!openedBudget)}
                disabled={
                  !validate ||
                  form.values.travelType === 7 ||
                  form.values.travelCommission === 1 ||
                  loadingBudget
                }
                rightSection={<LuChevronDown size="1rem" color="#004236" />}
                classNames={{
                  dropdown: classes.dropdown,
                  rightSection: classes.rightSection,
                  label: classes.label,
                  input: classes.input,
                  item: classes.item,
                }}
                icon={
                  loadingBudget ? (
                    <Loader size="1rem" color="#004236" />
                  ) : (
                    <FaUserAlt
                      size="1rem"
                      color={!!form?.values?.travelBudget?.length && "#004236"}
                    />
                  )
                }
              />
            </Popover.Target>
            <Popover.Dropdown style={{ marginTop: ".5rem" }}>
              <Grid justify="center" align="flex-start">
                <Grid.Col span="auto">
                  <TextInput
                    {...form.getInputProps("travelBudgetSearch")}
                    icon={<BiSearchAlt2 size="1rem" color="#004236" />}
                    placeholder={"Buscar Elemento Presupuestal"}
                    classNames={{
                      label: classes.label,
                      input: classes.input,
                    }}
                    rightSection={
                      form.values.travelBudgetSearch.length > 0 && (
                        <FaPlus
                          size="1rem"
                          color="#004236"
                          style={{
                            transform: "rotate(45deg)",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            form.setFieldValue("travelBudgetSearch", "")
                          }
                        />
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span="content">
                  <Button
                    disabled={!form.isValid("travelBudgetSearch")}
                    className={classes.button}
                    onClick={handleSearchBudgets}
                  >
                    Buscar
                  </Button>
                </Grid.Col>
              </Grid>

              {elementSearchEvent
                ? null
                : dataList(
                    searchBudgetElements,
                    handleChangeBugetElement,
                    false,
                    true
                  )}
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        <Grid.Col span={2} md={1}>
          <Popover
            position="bottom-start"
            opened={openedApprover}
            onChange={setOpenedApprover}
            closeOnClickOutside
          >
            <Popover.Target>
              <TextInput
                {...form.getInputProps("travelApproverInput")}
                placeholder="Aprobador"
                label="Aprobador"
                readOnly
                data-testid="travelApproverInput"
                onClick={() => setOpenedApprover(!openedApprover)}
                disabled={
                  form.values.travelType === 7 ||
                  hasPolitician ||
                  loadingListApprovers
                }
                rightSection={<LuChevronDown size="1rem" color="#004236" />}
                classNames={{
                  dropdown: classes.dropdown,
                  rightSection: classes.rightSection,
                  label: classes.label,
                  input: classes.input,
                  item: classes.item,
                }}
                icon={
                  loadingListApprovers ? (
                    <Loader size="1rem" color="#004236" />
                  ) : (
                    <FaUserAlt
                      size="1rem"
                      color={form.isDirty("travelApproverInput") && "#004236"}
                    />
                  )
                }
              />
            </Popover.Target>
            <Popover.Dropdown style={{ marginTop: "1rem" }}>
              <Text>Persona que aprueba su viaje</Text>
              <Group>
                <FaUserAlt size="1rem" color="#004236" />
                <Text>
                  {utils.lodash.startCase(
                    utils.lodash.camelCase(form.values.travelApprover.nombre)
                  )}
                </Text>
              </Group>
              {form.values.travelPreviousApprover.nombre.length > 0 && (
                <>
                  <Text mt={12}>Aprobador previo</Text>
                  <Group>
                    <FaUserAlt size="1rem" color="#004236" />
                    <Text>
                      {utils.lodash.startCase(
                        utils.lodash.camelCase(
                          form.values.travelPreviousApprover.nombre
                        )
                      )}
                    </Text>
                    <FaPlus
                      style={{
                        transform: "rotate(45deg)",
                        cursor: "pointer",
                      }}
                      data-testid="previous-approver-faPlus"
                      color="#004236"
                      onClick={handlePreviousApproverClick}
                    />
                  </Group>
                </>
              )}
              <Radio.Group
                {...form.getInputProps("travelApproverType")}
                name="travelApproverType"
                mt={12}
              >
                <Radio
                  value="travelApprover"
                  label="Cambiar aprobador"
                  className={classes.radioButton}
                  mb={8}
                  sx={{
                    label: {
                      fontSize: "1rem",
                    },
                  }}
                />
                <Radio
                  value="previousTravelApprover"
                  label="Buscar aprobador previo"
                  className={classes.radioButton}
                  mb={12}
                  sx={{
                    label: {
                      fontSize: "1rem",
                    },
                  }}
                />
              </Radio.Group>
              <TextInput
                {...form.getInputProps("travelApprovers")}
                icon={<BiSearchAlt2 size="1rem" />}
                placeholder="Buscar Aprobador"
                classNames={{
                  label: classes.label,
                  input: classes.input,
                }}
              />

              {form.values.travelApproverType === "previousTravelApprover"
                ? dataList(searchPreviousApprovers, handleChangeApprover, true)
                : dataList(searchApprovers, handleChangeApprover)}
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        {form.values.travelType === 1 && (
          <Grid.Col span={2}>
            <Select
              {...form.getInputProps("travelEmergencyType")}
              searchable
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              nothingFound="No hay resultados"
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              label="Descripción de Emergencia"
              icon={
                <FaUserDoctor
                  size="1rem"
                  color={form.isDirty("travelEmergencyType") && "#004236"}
                />
              }
              data={data?.travelEmergencyType}
              disabled={!data?.travelEmergencyType?.length}
            />
          </Grid.Col>
        )}
        {form.values.travelType === 7 && (
          <Grid.Col span={2}>
            <Select
              {...form.getInputProps("travelUnionarticleType")}
              searchable
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              nothingFound="No hay resultados"
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              label="Artículo sindical"
              icon={
                <MdArticle
                  size="1rem"
                  color={form.isDirty("travelUnionarticleType") && "#004236"}
                />
              }
              data={data?.travelUnionarticleType}
            />
          </Grid.Col>
        )}
        <Grid.Col span={2}>
          <Textarea
            {...form.getInputProps("travelObjective")}
            icon={
              <FaComment
                size="1rem"
                color={form.isDirty("travelObjective") && "#004236"}
              />
            }
            placeholder="Objetivo del viaje.Por favor, ingrese una descripción superior a 5 caracteres."
            label="Objetivo del viaje"
            autosize
            classNames={{
              label: classes.label,
              input: classes.input,
            }}
            minRows={4}
            maxRows={6}
            sx={{
              ".mantine-Textarea-icon": {
                top: "-66px",
              },
            }}
          />
        </Grid.Col>
      </Grid>
      <Checkbox
        {...form.getInputProps("termsOfService", { type: "checkbox" })}
        className={classes.checkBox}
        sx={{ label: { color: "white" } }}
        label="Certifico que para el objetivo del viaje registrado se requiere la presencialidad del viajero y que no puede realizarse a través de un medio de conexión remota."
      />
    </CardComponent>
  );
};

export default TravelerComponent;
