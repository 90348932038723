import { Tooltip, Flex, Text } from "@mantine/core";
import { BsQuestionCircle } from "react-icons/bs";

const Label = ({ title, description }) => {
  return (
    <Tooltip
      label={description}
      position="top"
      width={320}
      color="#fff"
      sx={{ color: "black" }}
      multiline
      withArrow
      events={{ hover: true, focus: true, touch: true }}
    >
      <Flex align="center">
        <Text color="white">{title}</Text>
        <BsQuestionCircle
          size="0.875rem"
          style={{ marginLeft: "0.5rem", color: "white" }}
        />
      </Flex>
    </Tooltip>
  );
};

export default Label;
