import { Flex, Text, Grid } from "@mantine/core";
import { BiSolidPlusCircle, BiSolidMinusCircle } from "react-icons/bi";

const Services = ({
  isOpen,
  setIsOpen,
  data,
  selected,
  setSelected,
  title,
  subtitle,
  hasNote = false,
  disabled,
  PaperComponent,
  useWindowSize,
  disabledOption = [],
  fareType,
}) => {
  const isMd = useWindowSize("md");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const SERVICES_ALIAS_CNT = {
    ESPTERREST: ["TRANSPRLOC", "VLRTAXI"],
    HOTEL: ["VLRALOJAM"],
  };

  const servicesSelectedFilter = disabledOption
    ?.filter(({ alias }) => SERVICES_ALIAS_CNT[alias])
    ?.flatMap(({ alias }) => SERVICES_ALIAS_CNT[alias]);

  const filteredData =
    fareType?.tipoUsuario === "100" &&
    userData?.datosUsuario?.permisosUnicos?.includes("viajes Cenit")
      ? data?.map((item) => ({
          ...item,
          disabledOption: servicesSelectedFilter?.some(
            (aliasValue) => aliasValue === item.alias
          ),
        }))
      : data;

  const toggleSelected = (option) => {
    if (selected.some((item) => item.value === option.value)) {
      setSelected(selected.filter((item) => item.value !== option.value));
    } else {
      setSelected([...selected, option]);
    }
  };

  const handleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <Flex justify="space-between" align="center">
        <Text size="sm" c="white" sx={{ padding: "1rem 0" }}>
          <Text
            span
            c="#cbd300"
            inherit
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                display: "block",
                fontSize: "1.25rem",
              },
            })}
            mr={4}
          >
            {title}
          </Text>
          <Text span>{subtitle}</Text>
        </Text>
        {!isOpen ? (
          <BiSolidPlusCircle
            size="1.25rem"
            style={{
              flexShrink: 0,
              color: "#cbd300",
              display: !isMd && "none",
            }}
            onClick={handleOpen}
          />
        ) : (
          <BiSolidMinusCircle
            size="1.25rem"
            style={{
              flexShrink: 0,
              color: "#cbd300",
              display: !isMd && "none",
            }}
            onClick={handleOpen}
          />
        )}
      </Flex>
      {hasNote && (
        <Text size="sm" c="white" pb="1rem">
          <Text
            span
            c="#cbd300"
            inherit
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                display: "block",
                fontSize: "1.25rem",
              },
            })}
            mr={4}
          >
            Nota:
          </Text>
          <Text span>
            Los servicios de viajes son pagados directamente a los prestadores
            de servicios.
          </Text>
        </Text>
      )}
      {(isOpen || !isMd) && (
        <Grid columns={data?.length}>
          {filteredData?.map((item) => (
            <Grid.Col xs={data.length} md={1} key={item.value}>
              <PaperComponent
                data-testid="toggle-button"
                disabled={item.disabledOption || disabled}
                title={item.title}
                clicked={selected
                  .some((sel) => sel.value === item.value)
                  .toString()}
                icon={item.icon}
                onClick={() => toggleSelected(item)}
              />
            </Grid.Col>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Services;
