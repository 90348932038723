import {
  subscribeLib,
  useStyles,
  CardComponent,
  SelectItem,
} from "@mfe/js-common-ave-uiutils";
import { useMsal } from "@mfe/react-common-ave-msal";
import { useState, useEffect } from "react";
import {
  lodash,
  fetchTravelTypeData,
  fetchTravelCommissionTypeData,
  fetchBudgetElementsData,
  fetchApproversData,
  fetchTravelChangeUserData,
  fetchEmergencyTypeData,
  fetchUnionarticleTypeData,
  fetchUserSummaryData,
  fetchPreApproversData,
  fetchThirdTravelChangeUserData,
  fetchGraphPhotoById,
  filterEmpty,
  fetchUpdateProfileManager,
} from "@mfe/ts-common-ave-utilitaries";
import TravelerComponent from "../components/traveler.component";
import { Text } from "@mantine/core";

export default function TravelerView({
  tokenApi,
  form,
  organization,
  dataUserSummary,
  setDataUserSummary,
  fareType,
  setModalContent,
  open,
  close,
  hasPolitician,
  savedApprovers,
  setSavedApprovers,
  managerNumber,
}) {
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [dataUser, setDataUser] = useState(userData);
  const [opened, setOpened] = useState(false);
  const [elementSearchEvent, setElementSearchEvent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingChangeManager, setLoadingChangeManager] = useState(false);
  const [openedApprover, setOpenedApprover] = useState(false);
  const [openedBudget, setOpenedBudget] = useState(false);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [loadingListApprovers, setLoadingListApprovers] = useState(false);
  const [loadingsChangeUser, setLoadingsChangeUser] = useState({
    photo: false,
    data: false,
  });
  const [photo, setPhoto] = useState(null);

  const isEdit = JSON.parse(localStorage.getItem("isEdit"));

  const [data, setData] = useState({
    travelType: [],
    travelCommission: [],
    travelBudget: {
      default: { Codigo: "", TipoElementoPresupuestal: "" },
      elements: [],
    },
    travelThirdBudget: [],
    travelApprovers: [],
    travelPreApprovers: [],
    travelEmergencyType: [],
    travelUnionarticleType: [],
    travelChangeUser: [],
    travelThirdChangeUser: [],
    approversDefault: [],
    preApproversDefault: [],
  });

  const handleSearchUser = () => {
    form.values.thirdParties
      ? fetchThirdTravelChangeUserData(
          setData,
          tokenApi,
          setLoading,
          form.values.travelChangeUser
        )
      : fetchTravelChangeUserData(
          setData,
          tokenApi,
          form.values.travelChangeUser,
          dataUser,
          setLoading
        );
  };

  const handleChangeTravelUser = (item) => {
    setPhoto(null);
    fetchUserSummaryData(
      setDataUserSummary,
      setLoadingsChangeUser,
      tokenApi,
      item.codigoEmpleado
    );
    setOpened(!opened);
    form.setFieldValue("changeUser", true);
    form.setFieldValue("changeTravelID", item.codigoEmpleado);
    form.setFieldValue("travelChangeUser", "");
    setData((prev) => ({
      ...prev,
      travelChangeUser: [],
      travelThirdChangeUser: [],
    }));
    form.setFieldValue("travelBudget", "");
    setData((prev) => ({
      ...prev,
      travelType: [],
    }));
  };

  const handleSearchBudgets = (item) => {
    setElementSearchEvent(false);
    fetchBudgetElementsData(
      setData,
      tokenApi,
      {
        ...(dataUserSummary.travelUserSummary.codigoEmpleado
          ? dataUserSummary.travelUserSummary
          : dataUser.datosUsuario),
        ...form.values,
      },
      setModalContent,
      open,
      setLoadingBudget
    );
  };

  useEffect(() => {
    if (tokenApi) {
      if (form.isDirty("travelCommission") || form.values.travelType === 6) {
        fetchApproversData(
          setData,
          tokenApi,
          form.values.travelType,
          dataUserSummary?.travelUserSummary?.codigoEmpleado ||
            dataUser?.datosUsuario?.codigoEmpleado,
          dataUserSummary?.travelUserSummary?.codCargo ||
            dataUser?.datosUsuario?.codCargo,
          dataUserSummary?.travelUserSummary?.tipoFuncionario ||
            dataUser?.datosUsuario?.tipoFuncionario,
          setLoading,
          setLoadingListApprovers
        );
        fetchPreApproversData(
          setData,
          tokenApi,
          form.values.travelType,
          dataUserSummary?.travelUserSummary?.codigoEmpleado ||
            dataUser.datosUsuario.codigoEmpleado,
          form.values.travelCommission,
          dataUserSummary?.travelUserSummary?.codCargo ||
            dataUser?.datosUsuario?.codCargo,
          dataUserSummary?.travelUserSummary?.tipoFuncionario ||
            dataUser?.datosUsuario?.tipoFuncionario,
          setLoading
        );
        if (form.values.changeUser && form.values.thirdParties) {
          fetchBudgetElementsData(
            setData,
            tokenApi,
            {
              dataUserSummary: dataUserSummary.travelUserSummary,
              userData: dataUser.datosUsuario,
              ...form.values,
            },
            setModalContent,
            open,
            setLoadingBudget
          );
        } else {
          fetchBudgetElementsData(
            setData,
            tokenApi,
            {
              ...(dataUserSummary.travelUserSummary.codigoEmpleado
                ? dataUserSummary.travelUserSummary
                : dataUser.datosUsuario),
              ...form.values,
            },
            setModalContent,
            open,
            setLoadingBudget
          );
        }
      }
    }
    form.clearFieldError("travelCommission");
    subscribeLib("userData", (msg, data) => {
      setDataUser(data);
    });
  }, [
    form.values.travelType,
    form.values.travelCommission,
    dataUserSummary.travelUserSummary,
    form.values.thirdParties,
    dataUser.responseGraph.photo,
    tokenApi,
  ]);

  useEffect(() => {
    if (tokenApi) {
      fetchTravelTypeData(
        setData,
        tokenApi,
        dataUser.datosUsuario,
        dataUserSummary?.travelUserSummary,
        setModalContent,
        open
      );
    }
  }, [dataUserSummary?.travelUserSummary?.codigoEmpleado, tokenApi]);

  useEffect(() => {
    if (data?.travelApprovers?.length > 0 && !isEdit) {
      const approverDefault = data?.travelApprovers?.find(
        (item) => item?.codigoEmpleado === data?.approversDefault?.[0]
      );
      handleChangeApprover(approverDefault, false);
    }
  }, [data?.travelApprovers?.length]);

  useEffect(() => {
    if (data?.travelPreApprovers?.length > 0 && !isEdit) {
      const preApproverDefault = data?.travelPreApprovers?.find(
        (item) => item?.codigoEmpleado === data?.preApproversDefault?.[0]
      );
      preApproverDefault?.codigoEmpleado &&
        handleChangeApprover(preApproverDefault, true);
    }
  }, [data?.travelPreApprovers?.length, form.values.travelApprover.nombre]);

  useEffect(() => {
    let textToShow = "";
    if (
      form.values.travelApprover.codigoEmpleado &&
      form.values.travelPreviousApprover.codigoEmpleado
    ) {
      textToShow = `${
        lodash
          .startCase(lodash.camelCase(form.values.travelApprover.nombre))
          .split(" ")[0]
      } y ${
        lodash
          .startCase(
            lodash.camelCase(form.values.travelPreviousApprover.nombre)
          )
          .split(" ")[0]
      }`;
    } else {
      textToShow = lodash.startCase(
        lodash.camelCase(form.values.travelApprover.nombre)
      );
    }
    form.setFieldValue("travelApproverInput", textToShow);
  }, [
    form.values.travelApprover.codigoEmpleado,
    form.values.travelPreviousApprover.codigoEmpleado,
  ]);

  const isDefaultElement = !data?.travelBudget?.default?.Codigo?.length
    ? null
    : `${data?.travelBudget?.default?.Codigo} / ${data?.travelBudget?.default?.TipoElementoPresupuestal}` ||
      {};

  const isDefaultElementContent = filterEmpty({ element: isDefaultElement });

  useEffect(() => {
    if (!isEdit) {
      form.setFieldValue(
        "travelBudget",
        form.values.travelType === 7 ||
          form.values.travelCommission === 1 ||
          form.values.thirdParties
          ? data?.travelBudget?.elements?.[0]?.value
          : isDefaultElementContent?.element
          ? isDefaultElementContent?.element
          : ""
      );
    }
  }, [data?.travelBudget]);

  useEffect(() => {
    if (form.values.travelType && form.values.travelCommission && !isEdit) {
      form.setFieldValue("travelCommission", "");
    }
    if (form.isDirty("travelType")) {
      fetchTravelCommissionTypeData(
        setData,
        tokenApi,
        form.values.travelType,
        dataUser?.datosUsuario?.tipoFuncionario
      );
      if (form.values.travelType === 1) {
        fetchEmergencyTypeData(setData, tokenApi, form.values.travelType);
      }
      if (form.values.travelType === 7) {
        fetchUnionarticleTypeData(setData, tokenApi, form.values.travelType);
      }
    }
  }, [form.values.travelType]);

  useEffect(() => {
    if (form.isDirty("travelType") && !!form.values.dates.length && !isEdit) {
      form.setFieldValue("origin", "");
      form.setFieldValue("destination", "");
      form.setFieldValue("dates", [null, null]);
    }
  }, [form.values.travelType, form.values.dates.length]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("isEdit");
      localStorage.removeItem("liquidationID");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchPhoto = async () => {
      try {
        setLoadingsChangeUser((prev) => ({ ...prev, photo: true }));
        const response = await fetchGraphPhotoById(
          instance,
          accounts,
          dataUserSummary.travelUserSummary.correoElectronico ||
            dataUser?.datosUsuario?.correoElectronico
        );

        setPhoto(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingsChangeUser((prev) => ({ ...prev, photo: false }));
      }
    };

    if (
      dataUserSummary.travelUserSummary.nombre ||
      dataUser?.datosUsuario?.nombre
    ) {
      fetchPhoto();
    }
  }, [dataUserSummary.travelUserSummary.nombre]);

  const handleChangeApprover = (item, isPrevious) => {
    const data = {
      nombre: item?.nombre || "",
      codigoEmpleado: item?.codigoEmpleado || "",
    };
    if (isPrevious) {
      setSavedApprovers((prev) => ({
        ...prev,
        preApprover: data,
      }));
      form.setFieldValue("travelPreviousApprover", data);
    } else {
      setSavedApprovers((prev) => ({
        ...prev,
        approver: data,
      }));
      form.setFieldValue("travelApprover", data);
    }
    setOpenedApprover(false);
  };

  const handleChangeBugetElement = (item) => {
    form.setFieldValue("travelBudget", item.value);
    form.setFieldValue("travelBudgetSearch", "");
    setOpenedBudget(!openedBudget);
  };

  useEffect(() => {
    if (
      !hasPolitician &&
      (Object.values(savedApprovers.approver).length > 0 ||
        Object.values(savedApprovers.preApprover).length > 0)
    ) {
      handleChangeApprover(savedApprovers.approver, false);
      handleChangeApprover(savedApprovers.preApprover, true);
    }
  }, [
    hasPolitician,
    JSON.stringify(savedApprovers.approver),
    JSON.stringify(savedApprovers.preApprover),
  ]);

  const travelCommisionMessage = () => (
    <Text c="#004236" ta="justify">
      Ha seleccionado el tipo de comisión
      <Text fw={700} span inherit>
        {`  Capacitación`}
      </Text>
      , confirmo que la presente comisión corresponde a una acción de formación
      citada por la
      <Text fw={700} span inherit>
        {` Universidad Ecopetrol`}
      </Text>
    </Text>
  );

  const handleChangeManagerProfile = async () => {
    try {
      setLoadingChangeManager(true);
      await fetchUpdateProfileManager(
        tokenApi,
        userData?.datosUsuario?.codigoEmpleado,
        managerNumber
      );
      location.reload();
    } finally {
      setLoadingChangeManager(false);
    }
  };

  useEffect(() => {
    if (form.values.travelCommission === 1 && false) {
      setModalContent({
        content: travelCommisionMessage(),
        showButtons: true,
        closeOnClickOutside: true,
        onClick: () => close(),
      });
      open();
    }
  }, [form.values.travelCommission]);

  const props = {
    loading,
    form,
    data,
    utils: { lodash, filterEmpty },
    handleChangeTravelUser,
    handleSearchUser,
    opened,
    setOpened,
    dataUser,
    dataUserSummary,
    photo,
    handleSearchBudgets,
    setData,
    elementSearchEvent,
    loadingsChangeUser,
    fareType,
    classes,
    CardComponent,
    SelectItem,
    handleChangeApprover,
    handleChangeBugetElement,
    openedApprover,
    setOpenedApprover,
    openedBudget,
    setOpenedBudget,
    organization,
    hasPolitician,
    loadingBudget,
    loadingListApprovers,
    handleChangeManagerProfile,
    managerNumber,
    loadingChangeManager,
  };

  return <TravelerComponent {...props} />;
}
