import TravelView from "../views/travel.view";
import TravelerView from "../views/traveler.view";
import { images, useStyles, ModalComponent } from "@mfe/js-common-ave-uiutils";
import { Grid, BackgroundImage, Flex, Text, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  getAccessTokenApi,
  fetchFareTypeData,
  fetchChangeProfileManager,
} from "@mfe/ts-common-ave-utilitaries";

export default function Travel({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <TravelComponents organization={organization} />
    </MsalProvider>
  );
}

export const TravelComponents = ({ organization }) => {
  const { classes } = useStyles();
  const [tokenApi, setTokenApi] = useState("");
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [managerNumber, setManagerNumber] = useState(0);
  const [dataUserSummary, setDataUserSummary] = useState({
    travelUserSummary: {
      nombre: "",
      nombreCargo: "",
      tipoNomina: "",
      jefeDirecto: "",
      codigoEmpleado: "",
    },
  });
  const [fareType, setFareType] = useState({
    tipoUsuario: "",
    idCategoria: "",
    desPerfilViajero: "",
  });
  const [hasPolitician, setHasPolitician] = useState(false);
  const [savedApprovers, setSavedApprovers] = useState({
    approver: {},
    preApprover: {},
  });

  const [openedModal, { open, close }] = useDisclosure(false);
  const [modalContent, setModalContent] = useState({
    content: "",
    showButtons: true,
    closeOnClickOutside: true,
    onClick: () => {},
  });

  const dataUser = JSON.parse(localStorage.getItem("userData")) || null;

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  const formTraveler = useForm({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      travelChangeUser: "",
      thirdParties: false,
      travelType: "",
      travelAproverBossBoss: "",
      thirdPartiesValue: "",
      travelCommission: "",
      travelBudget: "",
      travelBudgetSearch: "",
      travelApprovers: "",
      travelApproverInput: "",
      travelApprover: {
        nombre: "",
        codigoEmpleado: "",
      },
      travelPreviousApprover: {
        nombre: "",
        codigoEmpleado: "",
      },
      travelEmergencyType: "",
      travelUnionarticleType: "",
      travelObjective: "",
      termsOfService: false,
      dates: [null, null],
      origin: "",
      destination: "",
      searchDestination: "",
      searchOrigin: "",
      travelExpenses: {
        name: "",
        value: "",
      },
      travelApproverType: "travelApprover",
      travelExpensesDollar: false,
      changeUser: false,
      changeTravelID: "",
    },
    validate: {
      travelChangeUser: (value) =>
        value.length < 3
          ? "Debe ingresar al menos 3 caracteres"
          : value.length >= 20
          ? "No puede exceder los 20 caracteres"
          : null,
      travelType: (value) => (value ? null : "Seleccione un tipo de viaje"),
      travelBudgetSearch: (value) =>
        value.length >= 3 ? null : "Debe ingresar al menos 3 caracteres",
      travelCommission: (value) =>
        value ? null : "Seleccione un tipo de comisión",
      travelObjective: (value) =>
        value.length >= 5
          ? value.length >= 500
            ? "No puede superar los 500 caracteres"
            : null
          : "El objetivo del viaje tiene que tener al menos 5 caracteres",
      termsOfService: (value) => (value ? null : "Acepte los términos"),
    },
  });

  useEffect(() => {
    if (tokenApi) {
      fetchFareTypeData(
        setFareType,
        tokenApi,
        dataUserSummary?.travelUserSummary?.codigoEmpleado?.length > 0
          ? dataUserSummary?.travelUserSummary
          : dataUser?.datosUsuario,
        String(formTraveler.values.travelType)?.length > 0
          ? formTraveler.values.travelType
          : 0
      );
    }
  }, [
    tokenApi,
    dataUserSummary?.travelUserSummary?.codigoEmpleado,
    formTraveler.values.travelType,
  ]);

  const getDataManager = async () => {
    const { data } = await fetchChangeProfileManager(
      tokenApi,
      dataUser?.datosUsuario?.codigoEmpleado
    );
    setManagerNumber(data);
  };

  useEffect(() => {
    if (tokenApi) getDataManager();
  }, [tokenApi]);

  const validateTravelerFields = () => {
    if (Number(formTraveler.values.travelType) !== 6) {
      return (
        formTraveler.isValid("travelType") &&
        formTraveler.isValid("travelCommission") &&
        formTraveler.isValid("travelObjective") &&
        formTraveler.isValid("termsOfService")
      );
    } else {
      return (
        formTraveler.isValid("travelType") &&
        formTraveler.isValid("travelObjective") &&
        formTraveler.isValid("termsOfService")
      );
    }
  };

  return isAuthenticated ? (
    <>
      <ModalComponent
        opened={openedModal}
        onClose={close}
        title="Alerta"
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        <Flex direction="column" gap={8}>
          <Text fw={400}>{modalContent.content}</Text>
          {modalContent.showButtons && (
            <Flex gap={16} my={12}>
              <Button
                fullWidth
                onClick={modalContent.onClick}
                className={classes.button}
              >
                Continuar
              </Button>
              <Button
                fullWidth
                onClick={close}
                className={classes.buttonOutline}
              >
                Cancelar
              </Button>
            </Flex>
          )}
        </Flex>
      </ModalComponent>
      <BackgroundImage
        src={images.skyImageBackground}
        className={classes.backgroundImage}
      >
        <Grid
          maw={{ xs: "100%", md: "100%", lg: "80%" }}
          mx="auto"
          sx={(theme) => ({
            [theme.fn.smallerThan("md")]: {
              height: "100%",
            },
          })}
        >
          <Grid.Col md={5}>
            <TravelerView
              tokenApi={tokenApi}
              form={formTraveler}
              organization={organization}
              dataUserSummary={dataUserSummary}
              setDataUserSummary={setDataUserSummary}
              fareType={fareType}
              setModalContent={setModalContent}
              open={open}
              close={close}
              hasPolitician={hasPolitician}
              savedApprovers={savedApprovers}
              setSavedApprovers={setSavedApprovers}
              managerNumber={managerNumber}
            />
          </Grid.Col>
          <Grid.Col
            md={7}
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                paddingBottom: "3rem",
              },
            })}
          >
            <TravelView
              filled={!validateTravelerFields()}
              tokenApi={tokenApi}
              form={formTraveler}
              dataUserSummary={dataUserSummary}
              setDataUserSummary={setDataUserSummary}
              fareType={fareType}
              organization={organization}
              setModalContent={setModalContent}
              open={open}
              close={close}
              setHasPolitician={setHasPolitician}
              savedApprovers={savedApprovers}
              hasPolitician={hasPolitician}
            />
          </Grid.Col>
        </Grid>
      </BackgroundImage>
    </>
  ) : null;
};
