import React from "react";
import { Box, Flex, Text } from "@mantine/core";
import { FaPlus } from "react-icons/fa6";

export const TravelExpensesList = ({ data, setData, utils }) => {
  const currenciesTotal = utils?.currenciesSumTotal(
    data?.travelExpensesTypeSelected?.map((item) => ({
      ...item,
      value: Number(item.value),
    }))
  );

  return (
    !!data?.travelExpensesTypeSelected?.length && (
      <Box mt={16}>
        {data.travelExpensesTypeSelected.map((item, index) => (
          <Flex
            key={index}
            align="center"
            justify="space-between"
            mt={8}
            py={10}
            px={8}
            bg="white"
            sx={{
              borderRadius: "5px",
            }}
            fz={14}
          >
            <Text
              sx={{
                textTransform: "capitalize",
              }}
            >
              {item.name}
            </Text>
            <Text ml="auto">
              {item.currency === "COP"
                ? utils.formatter.format(item.value)
                : utils.formatterUSD.format(item.value)}
            </Text>
            <FaPlus
              size={24}
              style={{
                transform: "rotate(45deg)",
                cursor: "pointer",
                marginLeft: "12px",
              }}
              data-testid="plus-icon"
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  travelExpensesTypeSelected:
                    prev.travelExpensesTypeSelected.filter(
                      (_, i) => i !== index
                    ),
                }))
              }
            />
          </Flex>
        ))}
        <Flex
          align="center"
          justify="space-between"
          mt={8}
          py={10}
          px={8}
          bg="#cbd300"
          sx={{
            borderRadius: "5px",
          }}
        >
          <Text fw={700} color="#004236">
            Total
          </Text>
          <Text fw={700} color="#004236">
            {currenciesTotal.COP && utils.formatter.format(currenciesTotal.COP)}
            {currenciesTotal.COP && currenciesTotal.USD && ` + `}
            {currenciesTotal.USD &&
              utils.formatterUSD.format(currenciesTotal.USD)}
          </Text>
        </Flex>
      </Box>
    )
  );
};
