import { useState } from "react";
import {
  SegmentedControl,
  Box,
  Flex,
  Grid,
  Text,
  Select,
  Button,
  Center,
  Switch,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { FaCalendarDays } from "react-icons/fa6";
import { FaMapMarkerAlt, FaDollarSign } from "react-icons/fa";
import { BiSolidPlusCircle } from "react-icons/bi";
import { LuChevronDown } from "react-icons/lu";

import MultiDestinationCard from "./multiDestinationCard.component";
import { TravelExpensesList } from "./travelExpensesList.component";
import Services from "./services.component";
import Label from "./label.component";

const TravelComponent = ({
  loading,
  data,
  setData,
  multiTrips,
  setMultiTrips,
  form,
  value,
  setValue,
  filled,
  setServicesSelected,
  setTravelCostsSelected,
  travelCostsSelected,
  travelServicesExpensesData,
  servicesSelected,
  utils,
  handleClickSubmit,
  lastDate,
  setLastDate,
  CardComponent,
  classes,
  travelExpensesList,
  servicesList,
  GlobalCard,
  PaperComponent,
  useWindowSize,
  excludeDate,
  handleAddTravelExpensesType,
  handleAddMultiTrip,
  handleChangeInput,
  inputRef,
  fareType,
}) => {
  const [isOpenServices, setIsOpenServices] = useState(false);
  const [isOpenTravelCosts, setIsOpenTravelCosts] = useState(false);
  const dataUser = JSON.parse(localStorage.getItem("userData"));

  const isValidTravelType = (travelType) => {
    return [6, 1].includes(travelType);
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
        data-testid="loading-overlay"
      />
    );
  }

  return (
    <CardComponent title="Información del " subtitle="viaje" disabled={filled}>
      {isValidTravelType(form.values.travelType) ||
      (form.values.travelType === 7 &&
        form.values.travelCommission === 5) ? null : (
        <Box sx={{ padding: ".5rem 0" }}>
          <SegmentedControl
            value={value}
            onChange={setValue}
            classNames={{
              root: classes.rootSegmentedControl,
              indicator: classes.indicatorSegmentedControl,
            }}
            data={[
              { label: "Ida Y Vuelta", value: "idayvuelta" },
              { label: "Multidestino", value: "multidestino" },
            ]}
          />
        </Box>
      )}
      {value === "multidestino" && (
        <Text color="#fff" py={12} fz={15} fw={500}>
          Por favor, recuerde que para este tipo de comisión debe ir agregando
          los destinos con el botón Agregar destino.
        </Text>
      )}
      <Grid grow>
        <Grid.Col md={3}>
          <DatePickerInput
            {...form.getInputProps("dates")}
            locale={utils.es}
            icon={
              <FaCalendarDays
                size="1rem"
                style={{
                  color:
                    form.values.dates[0] && form.values.dates[1] && "#004236",
                }}
              />
            }
            rightSection={
              form.values.dates.includes(null) ? (
                <LuChevronDown size="1rem" color="#004236" />
              ) : null
            }
            type="range"
            allowSingleDateInRange
            minDate={lastDate || new Date()}
            label="Fechas del viaje"
            placeholder="Ingrese fechas"
            valueFormat="DD/MMM/YY"
            clearable
            classNames={{
              label: classes.label,
              input: classes.input,
              day: classes.calendar,
              rightSection: form.values.dates.includes(null)
                ? classes.rightSection
                : "",
            }}
            excludeDate={excludeDate}
            sx={classes.textOverflow}
            disabled={filled}
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Select
            {...form.getInputProps("origin")}
            placeholder="Ciudad Origen"
            data={utils
              .lodash(data?.travelDestinations)
              .sortBy("label")
              .value()
              .filter((city) => city.value !== form.values.destination)}
            disabled={
              form.values.dates.includes(null) ||
              data?.travelDestinations?.length === 0
            }
            nothingFound="No hay resultados"
            searchable
            searchValue={form.values.searchOrigin}
            onSearchChange={(value) =>
              form.setFieldValue("searchOrigin", value)
            }
            rightSection={<LuChevronDown size="1rem" color="#004236" />}
            classNames={{
              dropdown: classes.dropdown,
              rightSection: classes.rightSection,
              label: classes.label,
              input: classes.input,
              item: classes.item,
            }}
            icon={
              <FaMapMarkerAlt
                size="1rem"
                style={{
                  color: form.values.origin.length > 0 && "#004236",
                }}
              />
            }
            label={
              <Label
                title="Ciudad Origen"
                description="Para seleccionar la ciudad, por favor, dar click en el nombre."
              />
            }
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Select
            {...form.getInputProps("destination")}
            ref={inputRef}
            placeholder="Ciudad Destino"
            data={utils
              .lodash(data?.travelDestinations)
              .sortBy("label")
              .value()
              .filter((city) => city.value !== form.values.origin)}
            disabled={
              form.values.dates.includes(null) ||
              data?.travelDestinations?.length === 0
            }
            nothingFound="No hay resultados"
            searchable
            searchValue={form.values.searchDestination}
            onSearchChange={(value) =>
              form.setFieldValue("searchDestination", value)
            }
            rightSection={<LuChevronDown size="1rem" color="#004236" />}
            classNames={{
              dropdown: classes.dropdown,
              rightSection: classes.rightSection,
              label: classes.label,
              input: classes.input,
              item: classes.item,
            }}
            label={
              <Label
                title="Ciudad Destino"
                description="Para seleccionar la ciudad, por favor, dar click en el nombre."
              />
            }
            icon={
              <FaMapMarkerAlt
                size="1rem"
                style={{
                  color: form.values.destination.length > 0 && "#004236",
                }}
              />
            }
          />
        </Grid.Col>
      </Grid>
      <Services
        disabled={
          form.values.travelCommission === 7 ||
          data?.travelServices.length === 0
        }
        isOpen={isOpenServices}
        setIsOpen={setIsOpenServices}
        data={travelServicesExpensesData(
          data?.travelServices,
          data?.travelServices?.length > 0
            ? servicesList
            : servicesList.filter((item) => item.alias !== "ESPTERREST"),
          true
        )}
        selected={servicesSelected}
        setSelected={setServicesSelected}
        title="Servicios de viaje:"
        subtitle="seleccione los servicios que desea utilizar."
        hasNote
        PaperComponent={PaperComponent}
        useWindowSize={useWindowSize}
        fareType={fareType}
      />
      {(form.values.thirdParties && form.values.changeUser) ||
      form.values.travelType === 6 ? null : (
        <Services
          disabled={
            form.values.travelType === 6 ||
            form.values.travelCommission === 5 ||
            data?.travelExpenses.length === 0
          }
          isOpen={isOpenTravelCosts}
          setIsOpen={setIsOpenTravelCosts}
          data={travelServicesExpensesData(
            data?.travelExpenses,
            travelExpensesList,
            true
          )}
          selected={travelCostsSelected}
          setSelected={setTravelCostsSelected}
          title="Viáticos:"
          subtitle="deshabilite los servicios que NO desea utilizar."
          disabledOption={servicesSelected}
          PaperComponent={PaperComponent}
          useWindowSize={useWindowSize}
          fareType={fareType}
        />
      )}

      {value === "multidestino" && (
        <Flex>
          <Button
            rightIcon={<BiSolidPlusCircle size="1.25rem" color="white" />}
            mt={16}
            ml="auto"
            className={classes.button}
            sx={{ float: "right" }}
            disabled={
              form.values.dates.includes(null) ||
              form.values.origin === "" ||
              form.values.destination === ""
            }
            onClick={handleAddMultiTrip}
          >
            Agregar Destino
          </Button>
        </Flex>
      )}

      <MultiDestinationCard
        multiTrips={multiTrips}
        setMultiTrips={setMultiTrips}
        utils={{ parseDate: utils.parseLocale }}
        GlobalCard={GlobalCard}
        classes={classes}
      />

      {(form.values.thirdParties && form.values.changeUser) ||
      form.values.travelType === 6 ? null : (
        <Grid align="flex-end">
          <Grid.Col xs={12} md={form.values.travelType === 2 ? 5 : 7}>
            <Select
              {...form.getInputProps("travelExpenses.name")}
              label="Otros Gastos"
              description="Recuerde seleccionar el otro gasto, ingresar el valor y dar clic en el botón Agregar"
              transitionProps={{ transition: "pop" }}
              placeholder="Otros gastos"
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              data={data?.travelExpensesType}
              sx={{
                ".mantine-InputWrapper-description": {
                  color: "#eaeaea",
                  fontSize: "13px",
                },
                width: "100%",
              }}
              disabled={
                filled ||
                (form.values.travelType === 7 &&
                  form.values.travelCommission === 5 &&
                  dataUser?.datosUsuario?.filial === "CNT")
              }
            />
          </Grid.Col>

          <Grid.Col xs={12} md="auto">
            <TextInput
              value={form.values.travelExpenses.value}
              onChange={handleChangeInput}
              icon={
                <FaDollarSign
                  color={
                    !!form?.values?.travelExpenses?.value?.length && "#004236"
                  }
                  size="1rem"
                />
              }
              label="Valor"
              placeholder="Monto"
              sx={{ width: "100%" }}
              classNames={{ input: classes.input, label: classes.label }}
              disabled={!form.isDirty("travelExpenses.name")}
            />
          </Grid.Col>
          {form.values.travelType === 2 && (
            <Grid.Col xs={12} md="content" pb={16}>
              <Switch
                {...form.getInputProps("travelExpensesDollar")}
                label="USD"
                labelPosition="left"
                sx={{
                  ".mantine-Switch-label": {
                    color: "#fff",
                    fontWeight: 500,
                  },
                  "input:checked+.mantine-Switch-track": {
                    backgroundColor: "#cbd300",
                    borderColor: "#cbd300",
                  },
                  ".mantine-Switch-track": {
                    maxWidth: "2.8rem",
                  },
                }}
                disabled={filled}
              />
            </Grid.Col>
          )}
          <Grid.Col xs={12} md="content">
            <Button
              className={classes.button}
              fullWidth
              disabled={!form.isDirty("travelExpenses.value")}
              onClick={handleAddTravelExpensesType}
            >
              Agregar
            </Button>
          </Grid.Col>
        </Grid>
      )}

      <TravelExpensesList
        data={data}
        setData={setData}
        utils={{
          formatter: utils.formatter,
          currenciesSumTotal: utils.currenciesSumTotal,
          formatterUSD: utils.formatterUSD,
        }}
      />

      <Center h={100}>
        <Button
          w={200}
          className={classes.button}
          sx={{ float: "right" }}
          onClick={handleClickSubmit}
          disabled={
            filled ||
            (value !== "multidestino" && !form.isDirty("destination")) ||
            (value === "multidestino" && !(multiTrips.length > 1))
          }
        >
          Continuar
        </Button>
      </Center>
    </CardComponent>
  );
};

export default TravelComponent;
